import { useState } from 'react';
import { Box, Menu, MenuItem } from '@mui/material';
import { useCommands } from '@remirror/react';
import ButtonBase from './ButtonBase';
import 'remirror/styles/all.css';
import { BorderAll } from '@mui/icons-material';
const TableButton = () => {
  const { createTable, focus, deleteTable } = useCommands();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const createTableFn = (rows, cols) => {
    createTable({
      rowsCount: rows,
      columnsCount: cols,
      withHeaderRow: false
    });
    focus();
    setAnchorEl(null);
  };

  const deleteTableFn = () => {
    deleteTable();
    setAnchorEl(null);
  };

  return (
    <Box>
      <ButtonBase disabled={!createTable.enabled()} onClick={e => setAnchorEl(e.currentTarget)}>
        <BorderAll />
      </ButtonBase>
      <Menu id="remirror-table-menu" anchorEl={anchorEl} open={!!anchorEl} onClose={handleCloseMenu}>
        <MenuItem onClick={() => createTableFn(3, 3)}>3*3</MenuItem>
        <MenuItem onClick={() => createTableFn(5, 5)}>5*5</MenuItem>
        <MenuItem onClick={deleteTableFn}>Delete table</MenuItem>
      </Menu>
    </Box>
  );
};

export default TableButton;
