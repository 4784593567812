import httpClient from './axios';

const URL = '/v2/scenario_templates';

export const uploadScenario = async (scenario: File) => {
  const formData = new FormData();

  formData.append('zip_file', scenario, scenario.name);

  await httpClient.post(URL, formData);
};
