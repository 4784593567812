import { ATTACKIQ_ID } from '@attackiq/constants';
import { Create } from '@refinedev/mui';
import { Checkbox, FormControlLabel, TextField, Box } from '@mui/material';
import { useForm } from '@refinedev/react-hook-form';
import { Controller } from 'react-hook-form';
import JSONEditor from '../../components/inputs/JSONEditor/JSONEditor';

const TagSetsCreate = () => {
  const {
    refineCore: { formLoading },
    saveButtonProps,
    register,
    control,
    formState: { errors }
  } = useForm();

  return (
    <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Box
        component="form"
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
        autoComplete="off"
      >
        <Controller
          rules={{ required: 'Tag set name is required' }}
          name="name"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              value={value || ''}
              onChange={onChange}
              error={!!errors.name}
              helperText={errors.name?.message}
              margin="normal"
              label="Tag set name"
              id="name"
              name="name"
              autoFocus
              fullWidth
              required
            />
          )}
        />

        <FormControlLabel
          label="Visible"
          control={
            <Controller
              name="visible"
              control={control}
              render={({ field }) => {
                return <Checkbox onChange={e => field.onChange(e.target.checked)} checked={!!field.value} />;
              }}
            />
          }
        />
        <Controller
          name="meta_data"
          control={control}
          defaultValue={{}}
          render={({ field: { value, onChange } }) => {
            return <JSONEditor value={value} onChange={onChange} />;
          }}
        />
        <input
          type="hidden"
          {...register('company', {
            value: ATTACKIQ_ID
          })}
        />
      </Box>
    </Create>
  );
};

export default TagSetsCreate;
