import { Edit } from '@refinedev/mui';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useInvalidate, useNavigation, useNotification, useResource } from '@refinedev/core';
import httpClient from '../../utils/axios';
import DragAndDrop from '../../components/DragAndDrop';

const ConnectorEdit = () => {
  const [selectedFile, setSelectedFile] = useState<File | null | undefined>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { id, resourceName } = useResource();

  const { open } = useNotification();

  const { list } = useNavigation();

  const invalidate = useInvalidate();

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const url = `/v1/${resourceName}/${id}`;

      const formData = new FormData();

      formData.append('tar_file', selectedFile as File);
      await httpClient.put(url, formData);

      open?.({
        message: 'Connector updated successfully',
        type: 'success'
      });

      invalidate({
        resource: resourceName,
        invalidates: ['all']
      });
      list(resourceName);
    } catch (e) {
      open?.({
        // @ts-ignore
        message: e.response.data.detail,
        type: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Edit
      isLoading={isLoading}
      saveButtonProps={{
        onClick: handleSubmit
      }}
    >
      <Box component="form" onSubmit={handleSubmit} autoComplete="off">
        <Typography variant="subtitle2" sx={{ mb: 1 }}>
          Upload a new connector to replace this one
        </Typography>
        <DragAndDrop setSelectedFile={setSelectedFile} selectedFile={selectedFile} acceptType=".tar.gz" />
      </Box>
    </Edit>
  );
};

export default ConnectorEdit;
