import { useActive, useCommands } from '@remirror/react';
import { Code } from '@mui/icons-material';
import ButtonBase from './ButtonBase';

const ToggleCodeSnippetButton = () => {
  const { toggleCodeBlock, focus } = useCommands();
  const { codeBlock } = useActive();

  const handleClick = () => {
    toggleCodeBlock();
    focus();
  };

  return (
    <ButtonBase active={codeBlock()} disabled={!toggleCodeBlock.enabled()} onClick={handleClick}>
      <Code />
    </ButtonBase>
  );
};

export default ToggleCodeSnippetButton;
