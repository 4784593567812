import { useActive, useCommands } from '@remirror/react';
import { FormatListBulleted } from '@mui/icons-material';
import ButtonBase from './ButtonBase';

const ToggleBulletListButton = () => {
  const { toggleBulletList, focus } = useCommands();
  const { bulletList } = useActive();

  const handleClick = () => {
    toggleBulletList();
    focus();
  };

  return (
    <ButtonBase active={bulletList()} disabled={!toggleBulletList.enabled()} onClick={handleClick}>
      <FormatListBulleted />
    </ButtonBase>
  );
};

export default ToggleBulletListButton;
