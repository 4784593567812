import { JSONEditor as SvelteJSONEditor } from 'svelte-jsoneditor/dist/jsoneditor.js';
import { CSSProperties, useEffect, useRef } from 'react';

type Content = { json: any; text: undefined } | { json: undefined; text: string };

const JSONEditor: React.FC<{
  value: string;
  style?: CSSProperties | undefined;
  onError?: (error: Error | null) => void;
  onChange: (newContent: any) => void;
}> = props => {
  const { style, value, onChange, onError } = props;
  const refContainer = useRef<any>(null);
  const refEditor = useRef<any>(null);
  const refContent = useRef<Content>({ text: undefined, json: value });

  useEffect(() => {
    // create editor
    // @ts-ignore - SvelteJSONEditor is not typed
    refEditor.current = new SvelteJSONEditor({
      target: refContainer.current,
      props: {
        content: refContent.current
      }
    });

    return () => {
      // destroy editor
      if (refEditor.current) {
        refEditor.current.destroy();
        refEditor.current = null;
      }
    };
  }, []);

  // update props
  useEffect(() => {
    if (refEditor.current) {
      refEditor.current.updateProps({
        ...props,
        onChange: (newContent: Content) => {
          if (newContent.text) {
            try {
              onChange(JSON.parse(newContent.text));
              onError?.(null);
            } catch (e) {
              onError?.(e as Error);
            }
          } else {
            onChange(newContent.json);
          }
        }
      });
    }
  }, [props, onChange, onError]);

  return <div className="svelte-jsoneditor-react" ref={refContainer} style={style}></div>;
};

export default JSONEditor;
