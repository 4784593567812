import { ATTACKIQ_ID } from '@attackiq/constants';
import { Edit } from '@refinedev/mui';
import { Box, TextField } from '@mui/material';
import { useForm } from '@refinedev/react-hook-form';
import { Controller } from 'react-hook-form';

const ReportTypeEdit = () => {
  const {
    refineCore: { formLoading },
    register,
    control,
    saveButtonProps,
    formState: { errors }
  } = useForm();

  return (
    <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}
        component="form"
        autoComplete="off"
      >
        <Controller
          rules={{ required: 'Report type name is required' }}
          name="name"
          render={({ field: { onChange, value } }) => (
            <TextField
              error={!!errors.name}
              value={value || ''}
              onChange={onChange}
              helperText={errors.name?.message}
              margin="normal"
              required
              id="name"
              label="Report type name"
              fullWidth
              autoFocus
            />
          )}
          control={control}
        />

        <Controller
          rules={{ required: 'Report type description is required' }}
          name="template_path"
          render={({ field: { onChange, value } }) => (
            <TextField
              value={value || ''}
              onChange={onChange}
              error={!!errors.template_path}
              helperText={errors.template_path?.message}
              label="Report"
              fullWidth
              required
              multiline
              minRows={5}
              maxRows={10}
            />
          )}
          control={control}
        />

        <input
          type="hidden"
          {...register('company', {
            value: ATTACKIQ_ID
          })}
        />
      </Box>
    </Edit>
  );
};

export default ReportTypeEdit;
