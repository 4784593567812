import { ATTACKIQ_ID } from '@attackiq/constants';
import { Edit, useAutocomplete } from '@refinedev/mui';
import { Autocomplete, Box, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import { useForm } from '@refinedev/react-hook-form';
import { Controller } from 'react-hook-form';
import JSONEditor from '../../components/inputs/JSONEditor/JSONEditor';

const TagEdit = () => {
  const {
    refineCore: { formLoading, queryResult },
    saveButtonProps,
    register,
    control,
    formState: { errors }
  } = useForm();

  const { autocompleteProps } = useAutocomplete({
    resource: 'tag_sets',
    defaultValue: queryResult?.data?.data.tag_set,
    queryOptions: {
      enabled: !!queryResult?.data?.data.tag_set
    },
    onSearch: value => [
      {
        field: 'name',
        operator: 'contains',
        value
      }
    ]
  });

  return (
    <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Box
        component="form"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}
        autoComplete="off"
      >
        <Controller
          rules={{ required: 'Tag name is required' }}
          name="display_name"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              value={value || ''}
              onChange={onChange}
              error={!!errors.display_name}
              helperText={errors.display_name?.message}
              margin="normal"
              required
              fullWidth
              id="display_name"
              label="Name"
              name="display_name"
              autoFocus
            />
          )}
        />

        <FormControlLabel
          label="Visible"
          control={
            <Controller
              name="visible"
              control={control}
              render={({ field }) => {
                return <Checkbox onChange={e => field.onChange(e.target.checked)} checked={!!field.value} />;
              }}
            />
          }
        />

        <Controller
          control={control}
          name="tag_set"
          rules={{ required: 'Tagset is required' }}
          defaultValue=""
          render={({ field }) => (
            <Autocomplete
              {...autocompleteProps}
              {...field}
              onChange={(_, value) => {
                field.onChange(value?.id || null);
              }}
              getOptionLabel={item => {
                return item.name
                  ? item.name
                  : autocompleteProps?.options.find(p => p.id.toString() === item.toString())?.name ?? '';
              }}
              isOptionEqualToValue={(option, value) => {
                return value === undefined || option?.id?.toString() === value?.toString();
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Tagset"
                  margin="normal"
                  variant="outlined"
                  error={!!errors.tag_set}
                  helperText={errors.tag_set?.message}
                  required
                />
              )}
            />
          )}
        />

        {!formLoading && (
          <>
            <Typography variant="h6">Meta data</Typography>
            <Controller
              name="meta_data"
              control={control}
              defaultValue={queryResult?.data?.data['meta_data'] || {}}
              render={({ field: { value, onChange } }) => {
                return <JSONEditor value={value} onChange={onChange} />;
              }}
            />
          </>
        )}

        <input
          type="hidden"
          {...register('company', {
            value: ATTACKIQ_ID
          })}
        />
      </Box>
    </Edit>
  );
};

export default TagEdit;
