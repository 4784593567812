import { Info } from '@mui/icons-material';

import { useActive, useCommands } from '@remirror/react';

import ButtonBase from './ButtonBase';

const ToggleCalloutButton = () => {
  const { toggleCallout, focus } = useCommands();
  const { callout } = useActive();

  const handleClick = () => {
    toggleCallout();
    focus();
  };

  return (
    <ButtonBase active={callout()} disabled={!toggleCallout.enabled()} onClick={handleClick}>
      <Info />
    </ButtonBase>
  );
};

export default ToggleCalloutButton;
