import { useCommands } from '@remirror/react';
import { InsertPhotoRounded } from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';
import { sleep } from '@attackiq/utils';

import ButtonBase from './ButtonBase';

const ImageButton = () => {
  const { insertImage, uploadImage, focus } = useCommands();
  const handleDropAccepted = files => {
    files.forEach(file => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function() {
        uploadImage(async () => {
          await sleep(1000);
          return { src: reader.result };
        });
        focus();
      };
      reader.onerror = function(error) {
        console.error('Error: ', error);
      };
    });
  };

  const { getInputProps, open } = useDropzone({
    accept: 'image/*',
    onDropAccepted: handleDropAccepted
  });

  const handleClick = () => {
    open();
  };

  return (
    <ButtonBase onClick={handleClick} disabled={!insertImage.enabled()}>
      <input {...getInputProps()} />
      <InsertPhotoRounded color="black" />
    </ButtonBase>
  );
};

export default ImageButton;
