import httpClient from './axios';

const URL = '/v1/connectors';

export const uploadConnector = async (connector: File, method: 'post' | 'patch' = 'post') => {
  const formData = new FormData();

  formData.append('tar_file', connector, connector.name);

  const selectedMethod = method === 'post' ? httpClient.post : httpClient.patch;

  await selectedMethod(URL, formData);
};
