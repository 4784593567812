import { List, useDataGrid, EditButton, BooleanField, DateField, DeleteButton, useAutocomplete } from '@refinedev/mui';

import {
  Stack,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Box,
  TextField,
  Autocomplete,
  Button,
  Tooltip,
  Typography
} from '@mui/material';

import { DataGridPro, GridColumns } from '@mui/x-data-grid-pro';
import { CrudFilters, getDefaultFilter } from '@refinedev/core';
import { useForm } from '@refinedev/react-hook-form';
import { Controller } from 'react-hook-form';
import { useMemo } from 'react';

const TagsList = () => {
  const { autocompleteProps } = useAutocomplete({
    resource: 'tag_sets',
    fetchSize: 10000,
    onSearch: (query: string) => {
      return [
        {
          field: 'tag_set',
          operator: 'eq',
          value: query
        }
      ];
    }
  });

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'display_name',
        headerName: 'Name',
        width: 200,
        flex: 2,
        sortable: false,
        renderCell: params => {
          return (
            <Tooltip title={params.row.display_name}>
              <Typography variant="body1" noWrap>
                {params.row.display_name}
              </Typography>
            </Tooltip>
          );
        }
      },
      { field: 'tag_set_name', headerName: 'Tagset', width: 100, flex: 1 },
      {
        field: 'created',
        headerName: 'Created At',
        renderCell: ({ value }) => <DateField format="LLL" value={value} />,
        flex: 1,
        sortable: false
      },
      {
        field: 'modified',
        headerName: 'Updated At',
        renderCell: ({ value }) => <DateField format="LLL" value={value} />,
        flex: 1,
        sortable: false
      },
      {
        field: 'visible',
        headerName: 'Visible',
        renderCell: ({ value }) => (
          <BooleanField value={value} valueLabelFalse="Not visible" valueLabelTrue="Visible" />
        ),
        sortable: false
      },
      {
        field: 'actions',
        headerName: 'Actions',
        minWidth: 250,
        sortable: false,
        renderCell: params => {
          return (
            <Stack direction="row" spacing={1}>
              <EditButton hideText recordItemId={params.row.id} />
              <DeleteButton hideText recordItemId={params.row.id} />
            </Stack>
          );
        }
      }
    ],
    []
  );

  const { dataGridProps, filters, search } = useDataGrid({
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { tag_set, search } = params;

      filters.push({
        field: 'tag_set',
        operator: 'eq',
        value: tag_set
      });

      filters.push({
        field: 'search',
        operator: 'eq',
        value: search
      });

      return filters;
    },

    filters: {
      initial: [
        {
          field: 'tag_set',
          operator: 'eq',
          value: ''
        }
      ]
    }
  });

  const { control, handleSubmit } = useForm({
    defaultValues: {
      tag_set: getDefaultFilter('tag_set', filters, 'eq') || '',
      search: getDefaultFilter('search', filters, 'eq') || ''
    }
  });
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={3}>
        <Card>
          <CardHeader title="Filters" />
          <CardContent>
            <Box
              component="form"
              autoComplete="off"
              sx={{
                display: 'flex',
                flexDirection: 'column'
              }}
              onSubmit={handleSubmit(search)}
            >
              <Controller
                name="search"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    value={value || ''}
                    onChange={onChange}
                    label="Tag"
                    placeholder="Search by Tag name"
                    margin="normal"
                    fullWidth
                    autoFocus
                  />
                )}
              />

              <Controller
                control={control}
                name="tag_set"
                defaultValue={null}
                render={({ field }) => (
                  <Autocomplete
                    {...autocompleteProps}
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value?.id || null);
                    }}
                    getOptionLabel={item => {
                      return item.name
                        ? item.name
                        : autocompleteProps?.options.find(p => p.id.toString() === item.toString())?.name ?? '';
                    }}
                    renderOption={(props, option: any) => {
                      return (
                        <Box {...props} component="li" key={option.id}>
                          {option.name}
                        </Box>
                      );
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return value === undefined || option?.id?.toString() === value?.toString();
                    }}
                    renderInput={params => (
                      <TextField {...params} label="Select a TagSet" margin="normal" variant="outlined" />
                    )}
                  />
                )}
              />

              <br />
              <Button type="submit" variant="contained">
                Apply
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} lg={9}>
        <List canCreate>
          <DataGridPro {...dataGridProps} filterModel={undefined} disableColumnMenu autoHeight columns={columns} />
        </List>
      </Grid>
    </Grid>
  );
};

export default TagsList;
