import { useRef } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import AssessmentTemplateTypeSelector from 'apps/data/src/components/inputs/AssessmentTemplateTypeSelector/AssessmentTemplateTypeSelector';
import JSONEditor from 'apps/data/src/components/inputs/JSONEditor/JSONEditor';
import VendorProductSelector from 'apps/data/src/components/inputs/VendorProductSelector/VendorProductSelector';
import { useApiUrl } from '@refinedev/core';
import httpClient from '../../../../../utils/axios';
import { AiRemirror } from '@attackiq/components';

/*
 * Backwards compatibility note:
 * The assessment template icon can be set to whatever screen you want. If you upload a file using the
 * button it will hit /v1/files and it will populate the textfield with the file url.
 */

const GeneralTab = () => {
  const logoInputFileRef = useRef<null | HTMLInputElement>(null);
  const {
    control,
    setValue,
    formState: { errors }
  } = useFormContext();

  const apiUrl = useApiUrl();

  const handleLogoChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files === null) {
      return;
    }

    const file = e.target.files[0];

    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      const {
        data: { file_name }
      } = await httpClient.post<{ file_name: string }>(`${apiUrl}/files`, formData);

      setValue('icon', file_name);
    }
  };

  return (
    <>
      <Controller
        control={control}
        name="template_name"
        render={({ field: { onChange, value } }) => (
          <TextField
            value={value || ''}
            onChange={onChange}
            error={!!errors.template_name}
            helperText={errors.template_name?.message}
            margin="normal"
            fullWidth
            label="Assessment Template Name"
            required
            autoFocus
          />
        )}
      />
      <Typography sx={styles.descriptionLabel}>Assessment template description*</Typography>
      <Controller
        control={control}
        name="template_description"
        render={({ field: { onChange, value } }) => {
          if (value === undefined) {
            return <>Loading...</>;
          }
          return <AiRemirror value={value || ''} onChange={onChange} />;
        }}
      />

      <VendorProductSelector control={control} name="vendor_product_id" />
      <AssessmentTemplateTypeSelector
        control={control}
        rules={{
          required: 'Assessment template type is required'
        }}
        inputProps={{
          error: !!errors.project_template_type,
          helperText: errors.project_template_type?.message,
          required: true
        }}
        name="project_template_type"
      />
      <Box
        sx={{
          display: 'flex',
          gap: 2
        }}
      >
        <Controller
          control={control}
          name="icon"
          render={({ field: { onChange, value } }) => (
            <TextField
              value={value || ''}
              onChange={onChange}
              label="Icon"
              placeholder="Icon"
              margin="normal"
              fullWidth
            />
          )}
        />
        <input
          style={{ display: 'none' }}
          onChange={handleLogoChange}
          ref={logoInputFileRef}
          type="file"
          accept="image/*"
        />

        <Button
          onClick={() => {
            logoInputFileRef?.current?.click();
          }}
        >
          Upload&nbsp;icon
        </Button>
      </Box>
      <Typography variant="h6">Meta data</Typography>
      <Controller
        name="meta_data"
        control={control}
        render={({ field: { value, onChange } }) => {
          if (value === undefined) {
            return <>Loading...</>;
          }
          return <JSONEditor value={value || {}} onChange={onChange} />;
        }}
      />
    </>
  );
};

export default GeneralTab;

const styles = {
  descriptionLabel: {
    color: '#9f9f9f',
    fontFamily:
      'Montserrat,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol',
    fontWeight: 400,
    fontSize: 1,
    lineHeight: '1.4375em',
    whiteSpace: 'nowrap'
  }
};
