import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useUpdate } from '@refinedev/core';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { DateValidationError } from '@mui/x-date-pickers/internals/hooks/validation/useDateValidation';

import { useBoolean } from '@attackiq/hooks';

import useCurrentResource from '../hooks/useCurrentResource';

interface SetContentLastUpdatedDateProps<TResource> {
  resourceNameKey: { [TKey in keyof TResource]: TResource[TKey] extends string ? TKey : never }[keyof TResource];
}

interface BaseResource {
  last_updated: string;
}

export const SetContentLastUpdatedDate = <TResource extends BaseResource>({
  resourceNameKey
}: SetContentLastUpdatedDateProps<TResource>) => {
  const [isDialogOpen, { on: openDialog, off: closeDialog }] = useBoolean(false);
  const { id, resourceName, data } = useCurrentResource<TResource>();
  const [date, setDate] = useState<Date | null>(() => new Date());
  const [error, setError] = useState<DateValidationError | null>(null);
  const { mutateAsync, isLoading } = useUpdate();

  const handleSet = async () => {
    if (id && date) {
      date.setHours(0, 0, 0, 0);

      await mutateAsync({
        id: id,
        resource: resourceName,
        values: {
          last_updated: date.toISOString()
        }
      });

      closeDialog();
    }
  };

  useEffect(() => {
    if (data) {
      setDate(new Date(data.last_updated));
    }
  }, [data]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Button onClick={openDialog}>Set Last Updated Date</Button>

      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogTitle>{data?.[resourceNameKey]}</DialogTitle>
        <DialogContent>
          <DatePicker
            label="Last Updated Date"
            value={date}
            onChange={setDate}
            onError={setError}
            renderInput={props => <TextField sx={{ mt: 0.5 }} {...props} />}
            componentsProps={{
              actionBar: {
                actions: ['today']
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button onClick={handleSet} disabled={isLoading || !date || !!error}>
            Set
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};
