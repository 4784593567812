import { useController, UseControllerProps } from 'react-hook-form';
import { Autocomplete, AutocompleteProps, TextField, TextFieldProps } from '@mui/material';

type MyAutocompleteProps<TValue> = AutocompleteProps<TValue, boolean, boolean, boolean>;

type AutocompleteInputProps<TValue> = UseControllerProps &
  Omit<MyAutocompleteProps<TValue>, 'renderInput'> & {
    TextFieldProps?: TextFieldProps;
    renderInput?: MyAutocompleteProps<TValue>['renderInput'];
  };

const AiAutocompleteInput = <TValue extends unknown>({
  name,
  control,
  rules,
  defaultValue,
  onChange,
  TextFieldProps,
  ...restAutocompleteProps
}: AutocompleteInputProps<TValue>) => {
  const {
    field,
    fieldState: { error }
  } = useController({
    name,
    control,
    rules,
    defaultValue
  });

  const handleChange: AutocompleteInputProps<TValue>['onChange'] = (event, data, reason) => {
    field.onChange(data);
    onChange?.(event, data, reason);
  };

  return (
    <Autocomplete
      {...field}
      onChange={handleChange}
      {...restAutocompleteProps}
      renderInput={
        restAutocompleteProps.renderInput ||
        (props => {
          return (
            <TextField
              {...props}
              error={!!error}
              helperText={error?.message}
              variant="outlined"
              color="secondary"
              {...TextFieldProps}
            />
          );
        })
      }
    />
  );
};

export default AiAutocompleteInput;
