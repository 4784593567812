import { ATTACKIQ_ID } from '@attackiq/constants';
import { Create } from '@refinedev/mui';
import { Box, TextField } from '@mui/material';
import { useForm } from '@refinedev/react-hook-form';

const ReportTypeCreate = () => {
  const {
    refineCore: { formLoading },
    register,
    saveButtonProps,
    formState: { errors }
  } = useForm();

  return (
    <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
        component="form"
        autoComplete="off"
      >
        <TextField
          {...register('name', { required: 'Report type name is required' })}
          error={!!errors.name}
          helperText={errors.name?.message}
          margin="normal"
          required
          id="name"
          name="name"
          label="Report type name"
          fullWidth
          autoFocus
        />

        <TextField
          {...register('template_path', { required: 'This field is required' })}
          error={!!errors.template_path}
          helperText={errors.template_path?.message}
          label="Report"
          fullWidth
          required
          multiline
          minRows={5}
          maxRows={10}
        />
        <input
          type="hidden"
          {...register('company', {
            value: ATTACKIQ_ID
          })}
        />
      </Box>
    </Create>
  );
};

export default ReportTypeCreate;
