import Editor, { OnChange } from '@monaco-editor/react';
import { useTheme } from '@mui/material/styles';
import { Box, Button, Card } from '@mui/material';
import { useSetAtom } from 'jotai';

import { schemaAtom, toggleAtom } from '../list';

const DescriptorJSONEditor = () => {
  const setSchema = useSetAtom(schemaAtom);
  const setToggle = useSetAtom(toggleAtom);
  const { palette } = useTheme();
  const { mode } = palette;

  const handleChange: OnChange = value => {
    try {
      const parsedSchema = JSON.parse(value || '');

      setSchema(parsedSchema);
    } catch {
      // do nothing
    }
  };

  const handleRenderForm = () => {
    setToggle(prev => !prev);
  };

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Card variant="outlined" sx={{ flex: '1 0 0' }}>
        <Editor
          defaultLanguage="json"
          theme={`vs-${mode}`}
          onChange={handleChange}
          defaultValue="{}"
          options={{
            tabSize: 2,
            minimap: {
              enabled: false
            }
          }}
        />
      </Card>

      <Button variant="contained" onClick={handleRenderForm}>
        Render form
      </Button>
    </Box>
  );
};

export default DescriptorJSONEditor;
