import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputLabel,
  NativeSelect,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';

import { useForm } from '@refinedev/react-hook-form';
import { Controller } from 'react-hook-form';
import React, { useState } from 'react';
import { ScenarioType } from '../../types';
import JSONEditor from '../../components/inputs/JSONEditor/JSONEditor';
import TabPanel from '../../components/mui/TabPanel/TabPanel';
import { Edit } from '@refinedev/mui';
import { ReactAsf } from '@attackiq/react-asf';
import { useList, useNavigation } from '@refinedev/core';
import { AiRemirror, AiReactJsonSchemaForm } from '@attackiq/components';
import { getIsReactJsonSchemaScenarios } from '../../utils/getIsReactJsonSchema';

enum TemplateTabs {
  General,
  Platforms,
  CloudProviders,
  Model,
  Descriptor,
  Phases,
  Scenarios
}

const ScenarioTemplateEdit = () => {
  const [tab, setTab] = useState<TemplateTabs>(TemplateTabs.General);
  const {
    refineCore: { queryResult, formLoading, onFinish, id },
    register,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    refineCoreProps: {
      queryOptions: {
        staleTime: 0,
        cacheTime: 0,
        structuralSharing: false
      }
    }
  });
  const isReactJsonSchema = getIsReactJsonSchemaScenarios(queryResult?.data?.data?.descriptor_json?.resources?.[0]);

  const { edit } = useNavigation();

  const handleTabChange = (_event: React.SyntheticEvent, value: number) => {
    setTab(value);
  };

  const { data: cloudProviders } = useList({
    resource: 'cloud_providers',

    pagination: {
      pageSize: 10000
    }
  });

  const { data: scenarios } = useList({
    resource: 'scenarios',
    filters: [{ field: 'scenario_template', value: id, operator: 'eq' }],

    pagination: {
      pageSize: 10000
    }
  });

  const onSubmit = handleSubmit(validData => onFinish(validData));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
    >
      <Paper variant="outlined">
        <Box sx={{ p: 2 }}>
          <Typography variant="subtitle2">Scenario ID: {queryResult?.data?.data.id}</Typography>
        </Box>
      </Paper>
      <Edit
        isLoading={formLoading}
        saveButtonProps={{
          onClick: onSubmit
        }}
      >
        <Box
          component="form"
          onSubmit={onSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2
          }}
        >
          <Controller
            rules={{ required: 'Scenario name is required' }}
            name="name"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                value={value || ''}
                onChange={onChange}
                error={!!errors.name}
                helperText={errors.name?.message}
                placeholder="Scenario name"
                label="Scenario name"
                autoFocus
                fullWidth
              />
            )}
          />
        </Box>
        <Box
          sx={{
            paddingY: 2
          }}
        >
          <Paper square variant="outlined">
            <Tabs value={tab} onChange={handleTabChange} aria-label="Sections of the scenario">
              <Tab label="General" />
              <Tab label="Platform" />
              <Tab label="Cloud Providers" />
              <Tab label="Model" />
              <Tab label="Descriptor" />
              <Tab label="Phases" />
              <Tab label="Scenarios" />
            </Tabs>

            <TabPanel value={tab} index={TemplateTabs.General}>
              <Typography variant="body1">Template description *</Typography>
              <Controller
                control={control}
                name="description"
                rules={{ required: 'Scenario description is required' }}
                render={({ field: { onChange, value } }) => {
                  if (value === undefined) {
                    return <>Loading...</>;
                  }
                  return <AiRemirror value={value || ''} onChange={onChange} />;
                }}
              />

              <Controller
                control={control}
                name="time_to_live"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    value={value || ''}
                    onChange={onChange}
                    error={!!errors.time_to_live}
                    helperText={errors.time_to_live?.message}
                    label="Time to live (ms)"
                    fullWidth
                  />
                )}
              />

              <Controller
                control={control}
                name="indicator_pattern"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    value={value || ''}
                    onChange={onChange}
                    error={!!errors.indicator_pattern}
                    helperText={errors.indicator_pattern?.message}
                    label="Indicator pattern"
                    fullWidth
                  />
                )}
              />

              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="scenario-type">
                  Scenario type
                </InputLabel>
                <NativeSelect
                  defaultValue={queryResult?.data?.data.scenario_type}
                  inputProps={{
                    name: 'scenario_type',
                    id: 'scenario-type'
                  }}
                  {...register('scenario_type', { required: true })}
                >
                  <option value={ScenarioType.Attack}>Attack</option>
                  <option value={ScenarioType.Validation}>Validation</option>
                </NativeSelect>
              </FormControl>
            </TabPanel>

            <TabPanel value={tab} index={TemplateTabs.Model}>
              <Controller
                control={control}
                defaultValue={queryResult?.data?.data.parameters_description}
                name="parameters_description"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    value={value || ''}
                    onChange={onChange}
                    fullWidth
                    multiline
                    label="Parameters description"
                    minRows={4}
                    maxRows={5}
                  />
                )}
              />

              <Typography variant="h6">Parameters:</Typography>
              {isReactJsonSchema ? (
                <AiReactJsonSchemaForm
                  schema={{ ...queryResult?.data?.data.descriptor_json.resources[0].schema, readonly: true }}
                  defaultFormData={queryResult?.data?.data.descriptor_json.resources[0].model}
                  uiSchema={{ 'ui:disabled': true }}
                  id="scenario-template-schema-form"
                />
              ) : (
                <ReactAsf
                  schema={{ ...queryResult?.data?.data.descriptor_json.resources[0].schema, readonly: true }}
                  form={queryResult?.data?.data.descriptor_json.resources[0].form}
                  model={{}}
                />
              )}
            </TabPanel>

            <TabPanel value={tab} index={TemplateTabs.Descriptor}>
              <Typography variant="h6">Descriptor JSON:</Typography>
              <Controller
                name="descriptor_json"
                control={control}
                defaultValue={queryResult?.data?.data.descriptor_json || {}}
                render={({ field: { value, onChange } }) => {
                  return <JSONEditor value={value} onChange={onChange} />;
                }}
              />
            </TabPanel>

            <TabPanel value={tab} index={TemplateTabs.Platforms}>
              <Typography variant="h6">Supported platforms:</Typography>
              <Controller
                name="supported_platforms"
                control={control}
                defaultValue={queryResult?.data?.data.supported_platforms}
                render={({ field: { value, onChange } }) => {
                  return <JSONEditor value={value} onChange={onChange} />;
                }}
              />
            </TabPanel>

            <TabPanel value={tab} index={TemplateTabs.CloudProviders}>
              <Controller
                name="cloud_providers"
                control={control}
                defaultValue={queryResult?.data?.data.cloud_providers}
                render={({ field: { value, onChange } }) => {
                  return (
                    <Autocomplete
                      multiple
                      id="cloud-providers-select"
                      options={cloudProviders?.data || []}
                      fullWidth
                      filterSelectedOptions
                      value={value || []}
                      onChange={(_event, cloudProviders) => {
                        const cloudProvidersIds = cloudProviders.map(cloudProvider =>
                          typeof cloudProvider === 'string' ? cloudProvider : cloudProvider.id
                        );
                        onChange(cloudProvidersIds);
                      }}
                      getOptionLabel={option => cloudProviders?.data.find(x => x.id === option)?.name || option.name}
                      isOptionEqualToValue={(option, value) => {
                        return option.id === value;
                      }}
                      renderInput={params => {
                        return <TextField {...params} label="Cloud providers" variant="outlined" />;
                      }}
                    />
                  );
                }}
              />
            </TabPanel>

            <TabPanel value={tab} index={TemplateTabs.Phases}>
              <ul>
                {queryResult?.data?.data.phases.map((phase: any) => (
                  <li key={phase.id}>
                    {phase.name}
                    <Button
                      onClick={() => {
                        edit('phases', phase.id);
                      }}
                    >
                      View
                    </Button>
                  </li>
                ))}
              </ul>
            </TabPanel>

            <TabPanel value={tab} index={TemplateTabs.Scenarios}>
              <ul>
                {scenarios?.data?.map((scenario: any) => (
                  <li key={scenario.id}>
                    {scenario.name} -
                    <Button
                      onClick={() => {
                        edit('scenarios', scenario.id);
                      }}
                    >
                      View
                    </Button>
                  </li>
                ))}
              </ul>
            </TabPanel>
          </Paper>
        </Box>
      </Edit>
    </Box>
  );
};

export default ScenarioTemplateEdit;
