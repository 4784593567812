import { useEffect, useRef, useState } from 'react';
import { Box, Button, Popover } from '@mui/material';
import { useActive, useCommands } from '@remirror/react';
import { useBoolean } from '@attackiq/hooks';
import { themeV5 } from '../../../AiThemeProvider/AiThemeProvider';

import { FormatColorText, Check, ExpandMore } from '@mui/icons-material';

const ColorButtonMenu = () => {
  const anchorElRef = useRef();
  const [isOpen, { on: openMenu, off: closeMenu }] = useBoolean(false);
  const [selectedColor, setSelectedColor] = useState(null);
  const { setTextColor } = useCommands();
  const { textColor } = useActive();
  const isAnyColorActive = textColor();
  const isDisabled = !setTextColor.enabled();

  useEffect(() => {
    if (!isAnyColorActive) {
      setSelectedColor(null);
    }
  }, [isAnyColorActive]);

  return (
    <>
      <Button
        id="color-menu-button"
        size="small"
        endIcon={<ExpandMore />}
        disabled={isDisabled}
        ref={anchorElRef}
        onClick={openMenu}
      >
        <FormatColorText />
      </Button>
      <Popover
        id="color-menu"
        aria-labelledby="color-menu-button"
        open={isOpen}
        anchorEl={anchorElRef.current}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        keepMounted
      >
        <Box
          sx={{
            px: 1,
            py: 0.5,
            gap: 0.5,
            display: 'grid',
            gridAutoFlow: 'column',
            gridTemplateRows: 'repeat(3, 1fr)'
          }}
        >
          {colors.flat().map(color => (
            <ColorBox
              key={color}
              color={color}
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
              onClose={closeMenu}
            />
          ))}
        </Box>
      </Popover>
    </>
  );
};

const ColorBox = ({ color, selectedColor, setSelectedColor, onClose }) => {
  const { focus, setTextColor, removeTextColor } = useCommands();
  const { textColor } = useActive();
  const isColorActive = textColor({ color });
  const isPrimary = color === primary['main'];

  const handleClick = () => {
    onClose();

    if (isPrimary) {
      removeTextColor();
      setSelectedColor(null);
    } else {
      setTextColor(color);
    }

    focus();
  };

  useEffect(() => {
    if (isColorActive) {
      setSelectedColor(color);
    }
  }, [isColorActive, color, setSelectedColor]);

  return (
    <Box
      role="button"
      bgcolor={color}
      color={getContrastText(color)}
      width={26}
      height={26}
      borderRadius={0.5}
      onClick={handleClick}
    >
      {textColor({ color }) || (isPrimary && !selectedColor && <Check color="inherit" />)}
    </Box>
  );
};

const { primary, info, success, warning, error, secondary, getContrastText } = themeV5.palette;

const colors = [
  [primary['main'], primary['light'], primary['contrastText']],
  [info['darkText'], info['main'], info['lightBg']],
  [success['darkText'], success['main'], success['lightBg']],
  [warning['darkText'], warning['main'], warning['lightBg']],
  [error['darkText'], error['main'], error['lightBg']],
  [secondary['darkText'], secondary['main'], secondary['light']]
];

export default ColorButtonMenu;
