import React from 'react';
import { useAutocomplete } from '@refinedev/mui';
import { Autocomplete, Box, TextField } from '@mui/material';
import { Control, Controller, FieldValues } from 'react-hook-form';

interface Props {
  control: Control<FieldValues, {}>;
  name: string;
  rules?: any;
  inputProps?: any;
}

const AssessmentTemplateTypeSelector: React.FC<Props> = ({ inputProps = {}, ...props }) => {
  const { autocompleteProps } = useAutocomplete({
    resource: 'assessment_template_types',
    fetchSize: 10000,
    onSearch: (query: string) => {
      return [
        {
          field: 'name',
          operator: 'eq',
          value: query
        }
      ];
    }
  });

  return (
    <Controller
      {...props}
      defaultValue={null}
      render={({ field }) => (
        <Autocomplete
          {...autocompleteProps}
          {...field}
          onChange={(_, value) => {
            field.onChange(value?.id || null);
          }}
          getOptionLabel={item => {
            return item.name
              ? item.name
              : autocompleteProps?.options.find(p => p.id.toString() === item.toString())?.name ?? '';
          }}
          renderOption={(props, option) => {
            return (
              <Box {...props} component="li" key={option.id}>
                {option.name}
              </Box>
            );
          }}
          isOptionEqualToValue={(option, value) => {
            return value === undefined || option?.id?.toString() === value?.toString();
          }}
          renderInput={params => (
            <TextField
              {...params}
              label="Assessment Template Type"
              margin="normal"
              variant="outlined"
              {...inputProps}
            />
          )}
        />
      )}
    />
  );
};

export default AssessmentTemplateTypeSelector;
