import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useModal, useUpdate } from '@refinedev/core';
import { Backdrop, Box, Button, CircularProgress, IconButton, Modal, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

const EditDescriptionTest: React.FC<{ testName: string; description: string; testId: string }> = ({
  testId,
  description,
  testName
}) => {
  const { visible, show, close } = useModal();
  const [value, setValue] = useState(description);

  const { mutateAsync: updateMutateAsync, isLoading: mutationInProgress } = useUpdate();
  const editDescriptionTest = async (newDescription: string) => {
    try {
      await updateMutateAsync({
        resource: 'assessment_template_tests',
        id: testId,
        values: {
          description: newDescription
        },
        invalidates: ['all']
      });
    } finally {
      close();
    }
  };

  return (
    <>
      <Button onClick={show}>Edit Description</Button>
      <Modal open={visible} onClose={close}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            bgcolor: 'background.paper',
            p: 3,
            overflow: 'auto'
          }}
        >
          <Backdrop open={mutationInProgress}>
            <CircularProgress />
          </Backdrop>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Typography variant="h4" component="h1">
                Edit description for the test "{testName}"
              </Typography>
              <IconButton onClick={close}>
                <Close />
              </IconButton>
            </Box>

            <Box
              sx={{
                py: 4,
                flexGrow: 1
              }}
            >
              <TextField
                value={value}
                onChange={e => setValue(e.target.value)}
                placeholder="Edit description for the test"
                label="Edit description for the test"
                fullWidth
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <LoadingButton
                loading={mutationInProgress}
                variant="contained"
                onClick={async () => editDescriptionTest(value)}
              >
                Save
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default EditDescriptionTest;
