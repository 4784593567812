import { useActive, useCommands } from '@remirror/react';
import { FormatItalic } from '@mui/icons-material';
import ButtonBase from './ButtonBase';

const ToggleItalicButton = () => {
  const { toggleItalic, focus } = useCommands();
  const { italic } = useActive();

  const handleClick = () => {
    toggleItalic();
    focus();
  };

  return (
    <ButtonBase active={italic()} disabled={!toggleItalic.enabled()} onClick={handleClick}>
      <FormatItalic />
    </ButtonBase>
  );
};

export default ToggleItalicButton;
