import { forwardRef } from 'react';
import merge from 'lodash/merge';

import type { SxProps } from '@mui/material';

// Link a style sheet with a component.
// It does not modify the component passed to it;
// instead, it returns a new component, with a `styles` property.
const withSxStyles =
  <TElement, TProps extends { styles?: Record<string, SxProps> }>(stylesOrCreator, options = { name: '' }) =>
  Component => {
    const WithSxStyles = forwardRef<TElement, TProps>(function WithSxStyles(props, ref) {
      const { styles: stylesProp = {}, ...restProps } = props;

      const styles = merge({}, stylesOrCreator, stylesProp);

      return <Component ref={ref} styles={styles} {...restProps} />;
    });

    // @ts-ignore: this is allowed but types with `forwardRef` are 💩
    WithSxStyles.displayName = `WithSxStyles(${options.name || Component.displayName || Component.name})`;

    return WithSxStyles;
  };

export default withSxStyles;
