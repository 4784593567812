import { useController, UseControllerProps } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';

export type TextFieldInputProps = UseControllerProps & TextFieldProps;

const TextFieldInput = ({ name, control, rules, defaultValue, onChange, ...TextFieldProps }: TextFieldInputProps) => {
  const {
    field,
    fieldState: { error }
  } = useController({
    name,
    control,
    rules,
    defaultValue
  });

  return (
    <TextField
      {...field}
      onChange={(...args) => {
        field.onChange(...args);
        onChange?.(...args);
      }}
      error={!!error}
      helperText={error?.message}
      variant="outlined"
      {...TextFieldProps}
    />
  );
};

export default TextFieldInput;
