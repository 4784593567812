/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable @typescript-eslint/no-this-alias */
import angular2react from './angular2react';
import { memo, useEffect, useState, useRef } from 'react';

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import root from 'react-shadow';
import bootstrap from '!!to-string-loader!css-loader!../../dist/css/bootstrap.min.css';
import fontAwesome from '!!to-string-loader!css-loader!../../dist/css/font-awesome.min.css';
import vendorScript from '!!raw-loader!../../dist/js/vendor.min.js';
import { aiPcapInput } from './directives';
import aiFileInput from './directives/aiFileInput.directive';
import FileFactory from './factories/file.factory';

const AiSchemaForm = memo(
  angular2react('aiSchemaForm', ['schema', 'form', 'model', 'onModelChange', 'onStateChange', 'scopeRef']),
  (prevProps, nextProps) => prevProps.toggle === nextProps.toggle
);

export default ({ fullHeight = false, ...props }) => {
  const [shouldShow, setShouldShow] = useState(false); // We need to delay AiSchemaForm constructor until we are sure angularJS is loaded
  const angularStyles = `
  [ng\\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak],
  .ng-cloak, .x-ng-cloak,
  .ng-hide:not(.ng-hide-animate) {
    display: none !important;
  }

  ng\\:form {
    display: block;
  }


  .Mui-focused a {
      color: #262626;
      text-decoration: none;
      background-color: #f5f5f5;
  }

  #file-input {
    display: none;
  }

  #pcap-input {
    display: none;
  }
`;

  const ref = useRef(null);

  useEffect(() => {
    window.ASFShadowRoot = ref.current.shadowRoot; // This needs to be set so the custom directives can find the elements
    if (window.angular === undefined) {
      const ASFScript = document.createElement('script');
      ASFScript.innerHTML = vendorScript;
      document.body.appendChild(ASFScript);
    }

    // Let the browser parse the script tag before trying to bootstrap the angularjs app
    setTimeout(() => {
      angular
        .module('ASFApp', ['schemaForm', 'ngResource', 'ngFileUpload', 'ui.sortable'])
        .config([
          '$interpolateProvider',
          'schemaFormDecoratorsProvider',
          function config($interpolateProvider, schemaFormDecoratorsProvider) {
            schemaFormDecoratorsProvider.addMapping(
              'bootstrapDecorator',
              'json-editor',
              'angular-schema-form-json-editor.html'
            );

            schemaFormDecoratorsProvider.addMapping('bootstrapDecorator', 'ace-editor', 'angular-ace-editor.html');

            schemaFormDecoratorsProvider.addMapping(
              'bootstrapDecorator',
              'ui-select',
              'angular-schema-form-ui-select.html'
            );

            schemaFormDecoratorsProvider.addMapping(
              'bootstrapDecorator',
              'ui-select-multiple',
              'angular-schema-form-ui-select-multiple.html'
            );

            $interpolateProvider.startSymbol('[[');
            $interpolateProvider.endSymbol(']]');
          }
        ])
        .factory('FileFactory', FileFactory)
        .directive('aiSchemaForm', function aiSchemaForm() {
          return {
            restrict: 'E',
            scope: {
              schema: '=',
              form: '=',
              model: '=',
              onModelChange: '=',
              onStateChange: '=',
              scopeRef: '='
            },
            replace: true,
            controllerAs: 'vm',
            bindToController: true,
            controller: 'aiSchemaFormController',
            template:
              '<div class="ai-schema-form"><form name="vm.schemaForm" sf-schema="vm.schema" sf-form="vm.form" sf-model="vm.model" autocomplete="off"></form></div>'
          };
        })
        .controller('aiSchemaFormController', [
          '$scope',
          '$timeout',
          function aiSchemaFormController($scope, $timeout) {
            var vm = this;
            vm.schemaForm = {};

            if (vm.scopeRef) {
              vm.scopeRef.current = $scope;
            }

            $scope.$watch(
              'vm.model',
              function(newVal, oldVal) {
                if (
                  typeof vm.onModelChange === 'function' &&
                  !isEmpty(newVal) &&
                  !isEmpty(oldVal) &&
                  !isEqual(newVal, oldVal)
                ) {
                  vm.onModelChange(newVal);
                }
              },
              true
            );

            $scope.$watchGroup(['vm.schemaForm.$invalid', 'vm.schemaForm.$pristine'], function() {
              if (typeof vm.onStateChange === 'function') {
                $timeout(function() {
                  vm.onStateChange(vm.schemaForm);
                });
              }
            });
          }
        ]);

      const angularApp = angular.element(document.querySelector('body')).injector();

      if (angularApp === undefined) {
        angular.element(document).ready(function() {
          angular.bootstrap(document, ['ASFApp']);
        });
      }

      // Registers custom directives
      angular.module('ASFApp').directive('aiPcapInput', aiPcapInput);
      angular.module('ASFApp').directive('aiFileInput', aiFileInput);

      setTimeout(() => {
        setShouldShow(true);
      }, 0);
    }, 0);

    return () => {
      window.ASFShadowRoot = null;
    };
  }, [ref]);

  return (
    <>
      <root.div
        ref={ref}
        mode="open"
        data-testid="shadow-root-react-asf"
        style={{ height: fullHeight ? '100%' : 'initial' }}
      >
        <div>
          {shouldShow && (
            <div
              style={{
                color: '#626262',
                backgroundColor: 'white',
                border: '1px solid #ccc',
                borderRadius: '4px',
                padding: '8px',
                height: 'fit-content'
              }}
            >
              <AiSchemaForm {...props} />
            </div>
          )}
        </div>
        <style type="text/css">{bootstrap}</style>
        <style type="text/css">{fontAwesome}</style>
        <style type="text/css">{angularStyles}</style>
      </root.div>
      {/* The browser doesn't load the fonts requested by the shadow-dom  */}
      <style type="text/css">{fontAwesome}</style>
    </>
  );
};
