import { ATTACKIQ_ID } from '@attackiq/constants';
import { Autocomplete, Box, Chip, TextField } from '@mui/material';
import { useCreate, useDelete, useList, useResource } from '@refinedev/core';
import { useState } from 'react';

const VendorProductTags = () => {
  const { id: vendorProductId } = useResource();
  const [tagSearchValue, setTagSearchValue] = useState('');
  const { mutateAsync: createAsync } = useCreate();
  const { mutateAsync: deleteAsync } = useDelete();

  const { data: vendorProductTags, refetch: refetchVendorProducts } = useList({
    resource: 'vendor_product_tags',

    queryOptions: {
      select: data => {
        return data.data.filter(vendorProductTag => vendorProductTag.vendor_product === vendorProductId);
      },
      enabled: !!vendorProductId
    },

    pagination: {
      pageSize: 10000
    },

    sorters: [{ field: 'created', order: 'asc' }]
  });

  const { data: tags } = useList({
    resource: 'tags',

    pagination: {
      pageSize: 10000
    }
  });

  const addNewVendorProductTag = async (vendorProductId: string, tagId: string) => {
    try {
      await createAsync({
        resource: 'vendor_product_tags',
        values: {
          company: ATTACKIQ_ID,
          vendor_product: vendorProductId,
          tag: tagId
        }
      });
    } catch (e) {
      console.error(e);
    } finally {
      refetchVendorProducts();
    }
  };

  const removeVendorProductTag = async (vendorProductId: string) => {
    try {
      await deleteAsync({
        resource: 'vendor_product_tags',
        id: vendorProductId
      });
    } catch (e) {
      console.error(e);
    } finally {
      refetchVendorProducts();
    }
  };

  const tagOptions =
    tags?.data?.filter(tag => !vendorProductTags?.find(vendorProductTag => vendorProductTag['tag'] === tag.id)) || [];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1
        }}
      >
        {vendorProductTags?.map((vendorProductTag: any) => (
          <Chip
            onDelete={() => removeVendorProductTag(vendorProductTag.id)}
            size="small"
            key={vendorProductTag.id}
            label={`${vendorProductTag.tag_display_name}`}
          />
        ))}
      </Box>
      <Autocomplete
        disablePortal
        options={tagOptions}
        getOptionLabel={(option: any) => `${option.tag_set_name}: ${option.display_name}`}
        value={null}
        inputValue={tagSearchValue}
        onInputChange={(_event, value) => setTagSearchValue(value)}
        renderInput={(params: any) => <TextField {...params} label="Add tag" />}
        onChange={(_event, newlySelectedTag) => {
          addNewVendorProductTag(vendorProductId as string, newlySelectedTag?.id as string);
          setTagSearchValue('');
        }}
      />
    </Box>
  );
};

export default VendorProductTags;
