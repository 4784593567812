import { useForm } from '@refinedev/react-hook-form';
import { Controller, useFieldArray } from 'react-hook-form';
import { Delete as DeleteIcon } from '@mui/icons-material';
import VendorProductSelector from '../../components/inputs/VendorProductSelector/VendorProductSelector';
import { Edit } from '@refinedev/mui';
import { Box, Button, IconButton, Paper, TextField, Typography } from '@mui/material';
import { useModal } from '@refinedev/core';
import HistoryDrawer from '../scenarios/components/HistoryDrawer';
import { AiRemirror } from '@attackiq/components';

const MitigationsEdit = () => {
  const methods = useForm({
    refineCoreProps: {
      queryOptions: {
        cacheTime: 0,
        staleTime: 0,
        select: data => {
          return {
            data: {
              ...data.data,
              references: data.data['references'] || [] // References is sometimes null but it should be an empty array when there are no references
            }
          };
        }
      }
    }
  });

  const {
    refineCore: { queryResult, formLoading },
    control,
    saveButtonProps,
    formState: { errors }
  } = methods;

  const {
    fields: referenceFields,
    append,
    remove
  } = useFieldArray({
    control,
    name: 'references',
    shouldUnregister: true
  });

  const drawer = useModal();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
    >
      <Paper variant="outlined">
        <Box
          sx={{
            p: 2
          }}
        >
          <Typography variant="subtitle2">Mitigation ID: {queryResult?.data?.data['id']}</Typography>
          <Typography variant="subtitle2">Tracker ID: {queryResult?.data?.data['tracker_id']}</Typography>
        </Box>
        <Box sx={{ mx: 1.5 }}>
          <Button
            type="button"
            variant="contained"
            onClick={() => {
              drawer.show();
            }}
          >
            HISTORY
          </Button>
        </Box>
      </Paper>
      <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} component="form" autoComplete="off">
          <Controller
            rules={{ required: 'Mitigation name is required' }}
            name="name"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                value={value || ''}
                onChange={onChange}
                error={!!errors.name}
                helperText={errors.name?.message}
                label="Mitigation Name"
                autoFocus
                fullWidth
                required
              />
            )}
          />
          <Typography variant="body1">Description*</Typography>
          <Controller
            rules={{ required: 'Mitigation description is required' }}
            name="description"
            control={control}
            render={({ field: { onChange, value } }) => {
              if (value === undefined) {
                return <>Loading...</>;
              }
              return <AiRemirror value={value || ''} onChange={onChange} />;
            }}
          />
          <Typography variant="h6" component="h1">
            References
          </Typography>
          {referenceFields.map((field, index) => (
            <Box
              sx={{
                display: 'flex',
                gap: 2
              }}
              key={field.id}
            >
              <Controller
                rules={{ required: 'Reference title is required' }}
                name={`references.${index}.title`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    value={value || ''}
                    onChange={onChange}
                    error={!!errors.references?.[index]?.title}
                    helperText={errors.references?.[index]?.title?.message}
                    label="Reference title"
                    fullWidth
                    required
                  />
                )}
              />

              <Controller
                rules={{ required: 'Reference URL is required' }}
                control={control}
                name={`references.${index}.url`}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    value={value || ''}
                    onChange={onChange}
                    label="Reference URL"
                    fullWidth
                    required
                    error={!!errors.references?.[index]?.url}
                    helperText={errors.references?.[index]?.url?.message}
                  />
                )}
              />

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <IconButton onClick={() => remove(index)} aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>
          ))}
          <Button
            variant="outlined"
            onClick={() =>
              append({
                title: '',
                url: ''
              })
            }
          >
            Add new reference
          </Button>

          <VendorProductSelector control={control} name="vendor_product_id" />
        </Box>
      </Edit>
      <HistoryDrawer drawer={drawer} selectedId={queryResult?.data?.data['id'] as string} model="Mitigation" />
    </Box>
  );
};

export default MitigationsEdit;
