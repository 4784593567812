import React, { useRef, useState } from 'react';
import { useForm } from '@refinedev/react-hook-form';
import { Controller } from 'react-hook-form';
import { Create } from '@refinedev/mui';
import { TextField, Box, Typography } from '@mui/material';
import { ATTACKIQ_ID } from '@attackiq/constants';
import { useCreate, useNavigation } from '@refinedev/core';

const VendorCreate = () => {
  const [selectedLogo, setSelectedLogo] = useState<Blob | null>(null);

  const previewRef = useRef<HTMLImageElement | null>(null);

  const {
    refineCore: { formLoading },
    control,
    formState: { errors },
    handleSubmit
  } = useForm();

  const { mutateAsync: createMutateAsync } = useCreate();
  const { edit } = useNavigation();

  const onSubmit = handleSubmit(async (validData, e) => {
    const formData = new FormData();

    formData.append('name', validData.name);
    formData.append('company', ATTACKIQ_ID);

    if (selectedLogo) {
      formData.append('logo', selectedLogo);
    }

    const { data: newlyCreatedVendor } = await createMutateAsync({
      resource: 'vendors',
      values: formData,
      meta: {
        axiosConfigOverride: {
          transformRequest: () => {
            return formData;
          }
        }
      }
    });

    if (newlyCreatedVendor.id) {
      edit('vendors', newlyCreatedVendor.id);
    }
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file: Blob | null = (event.target.files?.[0] as Blob) ?? null;

    const logoObjectURL = URL.createObjectURL(file);
    setSelectedLogo(file);

    if (previewRef.current) {
      previewRef.current.src = logoObjectURL;
    }
  };

  return (
    <Create isLoading={formLoading} saveButtonProps={{ onClick: onSubmit }}>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        component="form"
        onSubmit={onSubmit}
        autoComplete="off"
      >
        <Controller
          name="name"
          control={control}
          rules={{ required: 'Vendor Name is required' }}
          render={({ field: { value, onChange } }) => (
            <TextField
              value={value || ''}
              onChange={onChange}
              required
              error={!!errors.name}
              helperText={errors.name?.message}
              label="Vendor Name"
              fullWidth
            />
          )}
        />

        <label htmlFor="logo-upload">
          <Typography variant="h6">Logo (Optional)</Typography>
        </label>

        <input type="file" id="logo-upload" onChange={handleChange}></input>

        <Box
          component="section"
          sx={{
            display: selectedLogo ? 'block' : 'none'
          }}
        >
          <Typography variant="h6">Preview</Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '72px',
              height: '72px'
            }}
            component="picture"
          >
            <Box
              sx={{
                width: '100%',
                height: '100%'
              }}
              component="img"
              ref={previewRef}
            />
          </Box>
        </Box>
      </Box>
    </Create>
  );
};

export default VendorCreate;
