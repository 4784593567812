import { FC, useState } from 'react';
import { UseAutocompleteProps } from '@mui/material';
import { useCreate, useMany, useModal, useNavigation, useResource } from '@refinedev/core';
import { DeleteButton, useAutocomplete, useDataGrid } from '@refinedev/mui';
import { DataGridPro, GridColumns } from '@mui/x-data-grid-pro';

import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  TextField
} from '@mui/material';

import { ATTACKIQ_ID, MODULE_CONTENT_MAP } from '@attackiq/constants';

import { Module, ModuleContent } from 'apps/data/src/types';

interface IContentModulesProps {
  contentType: keyof typeof MODULE_CONTENT_MAP;
}

const ContentModules: FC<IContentModulesProps> = ({ contentType }) => {
  const { id } = useResource();
  const { dataGridProps } = useDataGrid<ModuleContent>({
    resource: 'module_contents',

    filters: {
      permanent: [
        {
          field: 'content_id',
          operator: 'eq',
          value: id
        }
      ]
    }
  });
  const modulesIds = dataGridProps.rows.map((item: ModuleContent) => item.module_id);
  const { data: modulesData, isLoading: isLoadingModules } = useMany<Module>({
    resource: 'modules',
    ids: [...new Set(modulesIds)],
    queryOptions: {
      enabled: modulesIds.length > 0
    }
  });
  const { show } = useNavigation();
  const { show: showModal, ...modalProps } = useModal();

  const columns: GridColumns<ModuleContent> = [
    {
      field: 'module__display_name',
      headerName: 'Module',
      renderCell: ({ row }) => {
        if (isLoadingModules) return 'Loading...';

        const module = modulesData?.data.find(item => item.id === row.module_id);

        const handleClick = () => {
          show('modules', row.module_id);
        };

        return (
          <Link sx={{ cursor: 'pointer' }} onClick={handleClick}>
            {module?.display_name}
          </Link>
        );
      },
      flex: 1
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: params => <DeleteButton recordItemId={params.row.id} resource="module_contents" hideText />,
      sortable: false,
      maxWidth: 100
    }
  ];

  return (
    <>
      <CreateModuleContent contentType={contentType} {...modalProps} />
      <Button color="success" variant="contained" onClick={showModal} sx={{ alignSelf: 'flex-end' }}>
        Add To Module
      </Button>
      <DataGridPro {...dataGridProps} columns={columns} disableColumnFilter autoHeight />
    </>
  );
};

const CreateModuleContent = ({ contentType, visible, close }) => {
  const [module, setModule] = useState<Module | null>(null);
  const { autocompleteProps } = useAutocomplete<Module>({
    resource: 'modules',
    fetchSize: 10000,
    debounce: 400,
    onSearch: searchText => [
      {
        field: 'search',
        operator: 'eq',
        value: searchText
      }
    ]
  });
  const { id } = useResource();
  const { mutateAsync: createMutateAsync, isLoading } = useCreate();

  const handleChange: UseAutocompleteProps<Module, false, false, false>['onChange'] = (_event, value) => {
    setModule(value);
  };

  const handleCreate = async () => {
    if (module) {
      await createMutateAsync({
        resource: 'module_contents',
        values: {
          module_id: module.id,
          content_id: id,
          content_type_model: contentType,
          company_id: ATTACKIQ_ID,
          content_type_app_label: 'ai_cloud'
        }
      });

      close();
      setModule(null);
    }
  };

  return (
    <Dialog open={visible} onClose={close} fullWidth>
      <DialogTitle>Add {MODULE_CONTENT_MAP[contentType]} to:</DialogTitle>
      <DialogContent>
        <Autocomplete
          {...autocompleteProps}
          sx={{ mt: 0.5 }}
          onChange={handleChange}
          getOptionLabel={option => option.display_name || ''}
          renderInput={params => <TextField {...params} label="Module" />}
        />
      </DialogContent>
      <DialogActions>
        <Button color="success" variant="contained" onClick={handleCreate} disabled={!module || isLoading}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContentModules;
