import { useState } from 'react';
import { useNavigation, useNotification } from '@refinedev/core';
import { Box, Button, Card, CardHeader, CardContent, Typography } from '@mui/material';
import { useBoolean } from '@attackiq/hooks';
import DragAndDrop from '../../components/DragAndDrop';
import { uploadConnector } from '../../utils/uploadConnector';

type useBooleanType = [boolean, { on: () => void; off: () => void }];

const ConnectorCreate: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File | undefined | null>(null);
  const [isUploading, { on: setIsUploadingStarted, off: setIsUploadingFinished }] = useBoolean(false) as useBooleanType;
  const { list } = useNavigation();

  const { open } = useNotification();

  const handleSubmit = async () => {
    if (!selectedFile) {
      return;
    }

    try {
      setIsUploadingStarted();
      await uploadConnector(selectedFile);
      list('connectors');
    } catch (error) {
      open?.({
        type: 'error',
        message: (error as any).response.data.detail
      });
    } finally {
      setIsUploadingFinished();
    }
  };

  return (
    <Card>
      <Box sx={{ p: 2 }}>
        <CardHeader title={<Typography variant="h5">Upload connector</Typography>}></CardHeader>
        <CardContent>
          <DragAndDrop setSelectedFile={setSelectedFile} selectedFile={selectedFile} acceptType=".tar.gz" />
          <Button onClick={handleSubmit} disabled={isUploading} variant="contained">
            Upload connector
          </Button>
        </CardContent>
      </Box>
    </Card>
  );
};

export default ConnectorCreate;
