import { useForm } from '@refinedev/react-hook-form';
import { Controller } from 'react-hook-form';
import { Edit } from '@refinedev/mui';

import { Box, TextField, Typography, FormControlLabel, Checkbox, Card, CardHeader, CardContent } from '@mui/material';

import VendorSelector from '../../components/inputs/VendorSelector/VendorSelector';
import { useRef, useState } from 'react';
import { useNavigation, useUpdate } from '@refinedev/core';
import VendorProductTags from './components/VendorProductsTags';
import { AiRemirror } from '@attackiq/components';

const EXTERNAL_URL_REGEX = /^(http|https):\/\/[^ "]+$/;

const VendorProductEdit = () => {
  const [selectedLogo, setSelectedLogo] = useState<Blob | null>(null);

  const previewRef = useRef<HTMLImageElement | null>(null);

  const {
    refineCore: { formLoading, id, queryResult },
    control,
    formState: { errors },
    handleSubmit
  } = useForm();

  const { mutateAsync: updateMutateAsync } = useUpdate();
  const { list } = useNavigation();

  const onSubmit = handleSubmit(async (validData, e) => {
    const formData = new FormData();

    for (const [validKey, validValue] of Object.entries(validData)) {
      formData.append(validKey, validValue);
    }

    if (selectedLogo) {
      formData.append('logo', selectedLogo);
    }

    const { data: updatedVendorProduct } = await updateMutateAsync({
      resource: 'vendor_products',
      id: id as string,
      values: formData,
      meta: {
        axiosConfigOverride: {
          transformRequest: () => {
            return formData;
          }
        }
      }
    });

    if (updatedVendorProduct.id) {
      list('vendor_products');
    }
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file: Blob | null = (event.target.files?.[0] as Blob) ?? null;

    const logoObjectURL = URL.createObjectURL(file);
    setSelectedLogo(file);

    if (previewRef.current) {
      previewRef.current.src = logoObjectURL;
    }
  };

  return (
    <Box>
      <Edit
        isLoading={formLoading}
        saveButtonProps={{
          onClick: onSubmit
        }}
      >
        <Box
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2
          }}
          onSubmit={onSubmit}
          autoComplete="off"
        >
          <Controller
            name="name"
            control={control}
            rules={{ required: 'Vendor product name is required' }}
            render={({ field: { onChange, value } }) => (
              <TextField
                value={value || ''}
                onChange={onChange}
                error={!!errors.name}
                helperText={errors.name?.message}
                label="Name"
                required
                fullWidth
              />
            )}
          />

          <VendorSelector
            control={control}
            name="vendor"
            rules={{ required: 'Vendor is required' }}
            inputProps={{
              error: !!errors.vendor,
              helperText: errors.vendor?.message
            }}
          />

          <Typography variant="body1">Vendor product description*</Typography>

          <Controller
            name="description"
            control={control}
            rules={{ required: 'Description is required' }}
            render={({ field: { onChange, value } }) => {
              if (value === undefined) {
                return <>Loading...</>;
              }
              return <AiRemirror value={value || ''} onChange={onChange} />;
            }}
          />

          <Controller
            name="external_url"
            control={control}
            rules={{
              pattern: {
                value: EXTERNAL_URL_REGEX,
                message: 'External URL must be a valid URL'
              }
            }}
            render={({ field: { onChange, value } }) => (
              <TextField
                value={value || ''}
                onChange={onChange}
                error={!!errors.external_url}
                helperText={errors.external_url?.message}
                label="External URL"
              />
            )}
          />

          <FormControlLabel
            label="Visible"
            control={
              <Controller
                name="visible"
                control={control}
                render={({ field }) => {
                  return <Checkbox onChange={e => field.onChange(e.target.checked)} checked={!!field.value} />;
                }}
              />
            }
          />

          <Box component="section">
            <Typography variant="h6">Current logo</Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '72px',
                height: '72px'
              }}
              component="picture"
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%'
                }}
                component="img"
                src={queryResult?.data?.data?.logo}
              />
            </Box>
          </Box>

          <label htmlFor="logo-upload">
            <Typography variant="h6">New logo (Optional)</Typography>
          </label>

          <input type="file" id="logo-upload" onChange={handleChange}></input>

          <Box
            component="section"
            sx={{
              display: selectedLogo ? 'block' : 'none'
            }}
          >
            <Typography variant="h6">Preview</Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '72px',
                height: '72px'
              }}
              component="picture"
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%'
                }}
                component="img"
                ref={previewRef}
              />
            </Box>
          </Box>
        </Box>
      </Edit>

      <Card elevation={1} sx={{ marginTop: 2 }}>
        <CardHeader title={<Typography variant="h5">Edit Vendor Product Tags</Typography>}></CardHeader>
        <CardContent>
          <VendorProductTags />
        </CardContent>
      </Card>
    </Box>
  );
};

export default VendorProductEdit;
