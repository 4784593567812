import { useCallback, useState } from 'react';
import { useHelpers, useKeymap } from '@remirror/react';
import { sleep } from '@attackiq/utils';

async function saveContent(content) {
  console.info(content);
  // Fake API call
  await sleep(1000);
}

const useSaveHook = () => {
  const { getMarkdown } = useHelpers();

  const [state, setState] = useState({ saving: false, error: undefined });

  const handleSave = useCallback(() => {
    const markdown = getMarkdown();

    setState({ saving: true, error: undefined });

    saveContent(markdown)
      .then(() => {
        setState({ saving: false, error: undefined });
      })
      .catch(error => {
        setState({ saving: true, error });
      });

    return true;
  }, [getMarkdown]);

  useKeymap('Mod-s', handleSave);

  return state;
};

export default [useSaveHook];
