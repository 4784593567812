export * from './lib';

export const ATTACKIQ_ID = '906d5ec6-101c-4ae6-8906-b93ce0529060';

export const CONNECTOR_TYPE = {
  '4e19606c-82b8-4f72-808f-2f89eccb292d': 'Forwarder',
  '78cd1533-3e4f-4dff-8448-4a5f9ec1e885': 'SingleCollector',
  '8afc266f-d2d7-46c8-80de-cde94ef1dafc': 'AggregateCollector',
  'd7814ce3-edf2-44c2-b551-581ac842cfef': 'Validator'
};

export const MODULE_CONTENT_MAP = {
  scenario: 'Scenario',
  projecttemplate: 'Assessment Template',
  blueprinttemplate: 'Blueprint',
  attackgraphtemplate: 'Attack Graph Template',
  flexpackage: 'Flex Package'
};

export const TAGGED_ITEM_MAP = {
  Scenario: 'scenario',
  VendorProduct: 'vendor_product',
  VendorProductDomain: 'vendor_product_domain',
  Phase: 'phase',
  Mitigation: 'mitigation',
  Stage: 'stage'
};

export const SSO_TYPES = {
  SAML: '36d7f42f-73e4-479e-a950-70a42fcf3e28'
};

export const MITRE_TACTICS_UUID = '055cba1f-07fc-4be9-a238-d533f412108d';

export const MITRE_TACTICS_TAG_ID = 'bba8abb4-8af7-478c-9003-6a00487bb387';

export const SIGMA_VENDOR_ID = '7f7c5774-2a04-4743-a17d-db0d1f6a9d8f';

export const HEAP_IO_APP_ID = {
  development: '977568546',
  prod: '1822030442'
};

export const HEAP_VALID_CUSTOMER_TYPES = ['customer'];

export const PHASE_LOG_TRACE_TYPES = {
  MITIGATION: '6',
  REPORT: '5',
  ERROR: '4',
  WARNING: '3',
  INFORMATION: '2',
  DEBUG: '1'
};
