import { Edit } from '@refinedev/mui';
import { Box, Skeleton } from '@mui/material';
import { useForm } from '@refinedev/react-hook-form';
import { FieldValues, FormProvider, SubmitHandler } from 'react-hook-form';
import { useNavigation, useResource, useUpdate } from '@refinedev/core';

import { ATTACKIQ_ID } from '@attackiq/constants';
import { ActiveInput, FeaturedInput, ModulesInput, ScenarioTagIDSInput } from './create';
import DragAndDrop from '../../components/DragAndDrop';
import { useState } from 'react';
import { fileToBase64 } from '@attackiq/utils';
import { New } from '../../types';
import { TextFieldInput } from '@attackiq/components';
import useCurrentResource from '../../hooks/useCurrentResource';

const NewEdit = () => {
  const { isLoading } = useCurrentResource<New>();

  const [selectedFile, setSelectedFile] = useState<File | undefined | null>(null);
  const { mutateAsync: editMutateAsync, isLoading: isUpdating } = useUpdate();
  const { show } = useNavigation();

  const { id } = useResource();
  const { saveButtonProps, ...formMethods } = useForm<New>({
    refineCoreProps: {
      action: 'edit'
    },
    defaultValues: {
      content_id: '',
      content_type_name: '',
      description: '',
      url: '',
      company: ATTACKIQ_ID,
      featured: false,
      cover_image: '',
      date: '',
      id: '',
      modules: [],
      scenario_tag_ids: [],
      title: '',
      active: false,
      type: 'whats_new',
      order: 0
    }
  });

  const { handleSubmit } = formMethods;

  const onSubmit: SubmitHandler<New> = async newContent => {
    if (!id) throw new Error('No ID provided');

    const payload = {
      ...newContent,
      id
    };

    if (selectedFile) payload.cover_image = await fileToBase64(selectedFile);

    const { data: editedNew } = await editMutateAsync({
      id,
      resource: 'whats_new',
      values: payload
    });

    if (!editedNew.id) return;

    show('whats_new', editedNew.id);
  };

  return (
    <FormProvider {...formMethods}>
      <form name="edit-module-form" onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)} autoComplete="off">
        <Edit isLoading={isUpdating} recordItemId={id} saveButtonProps={{ type: 'submit' }} canDelete={false}>
          {isLoading ? (
            <Skeleton variant="rectangular" height={500} width="100%" />
          ) : (
            <Box display="flex" flexDirection="column" gap={2}>
              <ActiveInput />
              <TextFieldInput color="primary" name="title" label="Title" rules={{ required: 'Title is required' }} />
              <TextFieldInput
                color="primary"
                name="content_id"
                label="Content ID"
                rules={{ required: 'Content ID is required' }}
              />
              <TextFieldInput
                color="primary"
                name="content_type_name"
                label="Content Type Name"
                rules={{ required: 'Content Type Name is required' }}
              />
              <TextFieldInput
                color="primary"
                name="order"
                label="Order"
                type="number"
                rules={{ required: 'Order is required' }}
              />
              <TextFieldInput
                color="primary"
                multiline
                rows={5}
                name="description"
                label="Description"
                rules={{ required: 'Description is required' }}
              />
              <TextFieldInput color="primary" name="url" label="URL" rules={{ required: 'URL is required' }} />
              <ModulesInput />
              <ScenarioTagIDSInput />
              <FeaturedInput />
              <DragAndDrop
                setSelectedFile={setSelectedFile}
                selectedFile={selectedFile}
                acceptType=".png, .jpg, .jpeg, .svg"
              />
            </Box>
          )}
        </Edit>
      </form>
    </FormProvider>
  );
};

export default NewEdit;
