import {
  Box,
  Button,
  CSSObject,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer as MuiDrawer,
  Theme,
  Tooltip,
  styled
} from '@mui/material';
import { ChevronLeft, ChevronRight, ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';

import { IF } from '../IF';

import { atom, useAtom } from 'jotai';
import { useBoolean } from '@attackiq/hooks';

export type ChildDrawerNavRoute = Omit<DrawerNavRoute, 'children' | 'icon'>;
export interface DrawerNavRoute {
  path: string;
  label: string;
  icon: JSX.Element;
  action?: JSX.Element;
  children?: ChildDrawerNavRoute[];
}

export const drawerOpenAtom = atom(true, (get, set, update: boolean | undefined) => {
  const prev = get(drawerOpenAtom);

  set(drawerOpenAtom, update ?? !prev);
});

export const useDrawerOpenAtom = () => useAtom(drawerOpenAtom);

const drawerWidth = 260;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  boxShadow: theme.shadows[1],
  ...(open && {
    ...openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme)
  }),
  '& .MuiDrawer-paper': {
    paddingBottom: theme.spacing(2),
    position: 'static',
    border: 'none',
    ...(open && {
      ...openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme)
    })
  }
}));

export const AiAppNavDrawer = ({ navRoutes }: { navRoutes: DrawerNavRoute[] }) => {
  const [isDrawerOpen, toggleDrawer] = useDrawerOpenAtom();
  return (
    <Drawer variant="permanent" open={isDrawerOpen}>
      <List sx={{ p: 1, flexGrow: 1 }}>
        {navRoutes.map(navRoute => {
          const { path, label, icon, children, action } = navRoute;

          return (
            <Tooltip key={path} title={!isDrawerOpen ? label : ''} disableInteractive>
              <Box>
                {!children?.length ? (
                  <LeafRoute path={path} label={label} isDrawerOpen={isDrawerOpen} icon={icon} action={action} />
                ) : (
                  <ThreeRoute
                    path={path}
                    label={label}
                    isDrawerOpen={isDrawerOpen}
                    icon={icon}
                    action={action}
                    children={children}
                  />
                )}
              </Box>
            </Tooltip>
          );
        })}
      </List>

      <Button sx={{ color: 'text.primary' }} onClick={() => toggleDrawer()}>
        {isDrawerOpen ? <ChevronLeft /> : <ChevronRight />}
      </Button>
    </Drawer>
  );
};

const LeafRoute = ({
  path,
  isDrawerOpen,
  icon,
  action,
  label
}: {
  path: string;
  isDrawerOpen: boolean;
  icon?: JSX.Element;
  action?: JSX.Element;
  label: string;
}) => (
  <ListItem secondaryAction={action} sx={{ color: 'text.primary' }} disablePadding>
    <NavLink to={path} style={{ width: '100%', color: 'inherit' }}>
      {({ isActive }) => (
        <ListItemButton
          selected={isActive}
          sx={{ minHeight: 48, px: 2, justifyContent: isDrawerOpen ? 'initial' : 'center' }}
        >
          <ListItemIcon sx={{ minWidth: 0, mr: isDrawerOpen ? 2 : 'auto' }}>{icon}</ListItemIcon>
          <IF condition={isDrawerOpen}>
            <ListItemText primary={label} />
          </IF>
        </ListItemButton>
      )}
    </NavLink>
  </ListItem>
);

const ThreeRoute = ({
  path,
  isDrawerOpen,
  icon,
  action,
  label,
  children
}: {
  path: string;
  isDrawerOpen: boolean;
  icon?: JSX.Element;
  action?: JSX.Element;
  label: string;
  children: ChildDrawerNavRoute[];
}) => {
  const [isExpanded, { on: expand, off: collapse }] = useBoolean();
  return (
    <ListItem secondaryAction={action} sx={{ color: 'text.primary', flexDirection: 'column' }} disablePadding>
      <NavLink to={path} style={{ width: '100%', color: 'inherit' }}>
        {({ isActive }) => (
          <ListItemButton
            selected={isActive}
            sx={{ minHeight: 48, px: 2, justifyContent: isDrawerOpen ? 'initial' : 'center' }}
          >
            <ListItemIcon sx={{ minWidth: 0, mr: isDrawerOpen ? 2 : 'auto' }}>{icon}</ListItemIcon>
            <IF condition={isDrawerOpen}>
              <>
                <ListItemText primary={label} onClick={() => (isExpanded ? collapse() : expand())} />
                <IconButton
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    isExpanded ? collapse() : expand();
                  }}
                >
                  {isExpanded ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
                </IconButton>
              </>
            </IF>
          </ListItemButton>
        )}
      </NavLink>
      {children && isExpanded && (
        <List sx={{ width: '100%' }}>
          {children.map(({ path: childPath, label }) => {
            return (
              <ListItem key={childPath} sx={{ color: 'text.primary' }} disablePadding>
                <NavLink to={`${path}/${childPath}`} style={{ width: '100%', color: 'inherit' }}>
                  {({ isActive }) => (
                    <ListItemButton selected={isActive} sx={{ pl: 9 }}>
                      <IF condition={isDrawerOpen}>
                        <ListItemText primary={label} />
                      </IF>
                    </ListItemButton>
                  )}
                </NavLink>
              </ListItem>
            );
          })}
        </List>
      )}
    </ListItem>
  );
};
